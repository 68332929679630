.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f3f4f6; 
}

.login-content {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 350px;
  text-align: center;
}

.login-title {
  margin-bottom: 30px;
  color: #333;
  font-weight: 600;
  font-size: 24px; 
  letter-spacing: 1px;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.login-form-label {
  text-align: left;
  font-size: 14px;
  margin-bottom: 5px;
  color: #555;
}

.login-form-input {
  padding: 12px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.login-button {
  padding: 12px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #555;
}

.create-account-button {
  margin-top: 10px;
  padding: 10px 15px;
  font-size: 14px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.create-account-button:hover {
  background-color: #0056b3;
}

.employee-signup-button {
  margin-top: 10px;
  padding: 10px 15px;
  font-size: 14px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.employee-signup-button:hover {
  background-color: #218838;
}

@media (max-width: 768px) {
  .login-content {
    width: 90%;
    padding: 20px;
  }

  .login-title {
    font-size: 20px;
  }

  .toilet-icon {
    width: 80px;
  }
}
