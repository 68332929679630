.create-work-order-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .work-order-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .bathroom-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .bathroom-item {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .ticket-info {
    margin-top: 20px;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .submit-button {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  .bathroom-list {
    margin: 10px 0;
  }
  
  .bathroom-item {
    margin-bottom: 20px;
  }
  
  .ticket-list {
    margin-left: 20px;
    margin-top: 10px;
    border-left: 2px solid #ddd;
    padding-left: 10px;
  }
  
  .ticket-item {
    position: relative;
    margin-bottom: 10px;
  }
  
  .ticket-hover-menu {
    display: none;
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 100;
  }
  
  .ticket-item:hover .ticket-hover-menu {
    display: block;
  }
  