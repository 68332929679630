.facilities-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9fafc; 
}

.facilities-header {
  margin-bottom: 20px;
  text-align: center;
}

.facilities-header h2 {
  font-size: 2rem;
  color: #333;
  font-weight: 600;
}

.facilities-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); 
  gap: 20px;
  width: 100%;
  max-width: 800px;
  margin: 20px auto; 
  box-sizing: border-box; 
  padding: 0;
  list-style: none;
}


.facility-card {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  cursor: pointer;
}

.facility-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.facility-info h3 {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.facility-info p {
  margin: 5px 0;
  font-size: 0.9rem;
  color: #555;
}

.facility-info p strong {
  color: #222;
}

.facility-actions {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}

.facility-actions button {
  padding: 8px 12px;
  font-size: 0.9rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.facility-actions .verify-btn {
  background-color: #28a745;
  color: #fff;
}

.facility-actions .verify-btn:hover {
  background-color: #218838;
}

.facility-actions .unverify-btn {
  background-color: #ffc107;
  color: #fff;
}

.facility-actions .unverify-btn:hover {
  background-color: #e0a800;
}

.facility-actions .delete-btn {
  background-color: #dc3545;
  color: #fff;
}

.facility-actions .delete-btn:hover {
  background-color: #c82333;
}


.facility-actions button.disabled {
  background-color: #e0e0e0;
  color: #aaa;
  cursor: not-allowed;
}

.alert {
  color: red;
  font-weight: bold;
}

.map-container {
  width: 100%;
  max-width: 800px;
  margin-top: 30px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.marker-popup {
  font-size: 0.9rem;
  color: #333;
}

.marker-popup strong {
  font-weight: bold;
}

.marker-popup.verified {
  color: #28a745;
}

.marker-popup.unverified {
  color: #dc3545;
}

.leaflet-interactive {
  stroke-opacity: 0.7;
}

.verified-marker {
  background-color: #28a745;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.unverified-marker {
  background-color: #dc3545;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

@media (max-width: 768px) {
  .facilities-list {
    padding: 0 10px;
  }

  .facility-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .facility-actions {
    flex-direction: row;
    gap: 5px;
    margin-top: 10px;
  }

  .map-container {
    height: 400px;
  }
}
.custom-verified-marker {
  position: relative;
}

.verified-marker {
  width: 25px;
  height: 41px;
  background-color: red; 
  border-radius: 50% 50% 0 0; 
  transform: rotate(45deg); 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.verified-marker:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 8px;
  height: 8px;
  background: white;
  border-radius: 50%;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: white;
  border-radius: 8px;
  padding: 20px;
  max-width: 500px;
  width: 90%;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  z-index: 1001; 
  animation: fadeIn 0.3s ease-in-out;
}

.modal-content h3 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #333;
}

.modal-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.modal-form label {
  font-size: 1rem;
  font-weight: bold;
  color: #555;
}

.modal-form input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.cancel-button {
  padding: 8px 15px;
  font-size: 0.9rem;
  border: none;
  background: #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cancel-button:hover {
  background: #bbb;
}

.add-button {
  padding: 8px 15px;
  font-size: 0.9rem;
  border: none;
  background: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-button:hover {
  background: #0056b3;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
