.work-management-container {
  padding: 40px;
  max-width: 1100px;
  margin: auto;
  font-family: Arial, sans-serif;
  color: #444; 
  background-color: #f8f9fa; 
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.work-management-header h1 {
  font-size: 28px;
  text-align: center;
  color: #3a4a5a; 
  margin-bottom: 10px;
}

.work-management-header p {
  font-size: 16px;
  text-align: center;
  color: #6c757d; 
  margin-bottom: 20px;
}

.work-management-sections {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  flex-wrap: wrap;
  margin-top: 30px;
}

.work-section {
  flex: 1;
  min-width: 300px;
  max-width: 350px;
  border: none;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  background: linear-gradient(145deg, #e8ecef, #ffffff); 
  box-shadow: 4px 4px 10px #d1d9e0, -4px -4px 10px #ffffff; 
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.work-section:hover {
  transform: translateY(-10px);
  box-shadow: 6px 6px 12px #c2cad1, -6px -6px 12px #ffffff; 
}

.work-section h2 {
  font-size: 20px;
  color: #495057; 
  margin-bottom: 10px;
}

.work-section p {
  font-size: 14px;
  color: #868e96;
  margin-bottom: 20px;
}

.section-link {
  display: inline-block;
  padding: 12px 24px;
  background-color: #6c757d;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  border-radius: 25px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.section-link:hover {
  background-color: #495057; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.section-link:active {
  background-color: #343a40; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

@media (max-width: 768px) {
  .work-management-sections {
    flex-direction: column;
    align-items: center;
  }

  .work-section {
    max-width: 100%;
    margin-bottom: 20px;
  }
}
