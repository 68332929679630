.homepage-container {
  padding: 30px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #f4f6f9;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.welcome-section {
  text-align: center;
  margin-bottom: 40px;
}

.welcome-section h1 {
  font-size: 2rem;
  color: #003366;
}

.welcome-section p {
  font-size: 1.2rem;
  color: #555;
}

.quick-links-section {
  margin-bottom: 40px;
}

.quick-links-section h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #003366;
}

.quick-links {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.homepage-link {
  display: inline-block;
  background-color: #007bff;
  color: white;
  padding: 15px 25px;
  text-decoration: none;
  border-radius: 8px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.homepage-link:hover {
  background-color: #0056b3;
}

.recent-activity-section {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.recent-activity-section h2 {
  margin-bottom: 15px;
  color: #003366;
}

.recent-activity-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.recent-activity-item {
  margin-bottom: 15px;
  font-size: 0.95rem;
  color: #444;
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.recent-activity-item .timestamp {
  font-weight: bold;
  color: #0056b3;
}

.recent-activity-item .actor-name {
  font-weight: bold;
  color: #333;
}

.recent-activity-item .action {
  font-style: italic;
  color: #555;
}

.recent-activity-item .target-type {
  color: #777;
}

.recent-activity-item .target-name {
  font-weight: bold;
  color: #0056b3;
}
