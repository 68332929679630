.work-orders-container {
  padding: 40px;
  max-width: 1000px;
  margin: 0 auto;
  background: linear-gradient(145deg, #f5f7fa, #ffffff);
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
}

.work-orders-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.work-orders-header-title {
  font-size: 28px;
  font-weight: bold;
  color: #2c3e50;
}

.add-work-order-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
}

.add-work-order-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.control-group, .control-sort {
  display: flex;
  align-items: center;
  gap: 10px;
}

.control-group label, .control-sort label {
  font-size: 14px;
  font-weight: bold;
  color: #495057;
}

select {
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.05);
  transition: border 0.3s ease;
}

select:focus {
  border: 1px solid #007bff;
}

.work-orders-grouped {
  margin-top: 20px;
}

.work-order-group {
  margin-bottom: 30px;
  padding: 20px;
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 10px;
}

.group-title {
  font-size: 20px;
  font-weight: bold;
  color: #007bff;
  margin-bottom: 15px;
}

.work-order-item {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.work-order-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.work-order-item-title {
  font-size: 18px;
  color: #2c3e50;
  font-weight: bold;
}

.work-order-item-detail {
  font-size: 14px;
  color: #555;
  margin-top: 5px;
}
