.tickets-container {
  padding: 40px;
  max-width: 900px;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  color: #333;
}

.tickets-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #2c3e50;
}

.tickets-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.group-toggle-container {
  display: flex;
  gap: 10px;
}

.group-toggle-button {
  padding: 10px 20px;
  border: none;
  background-color: #e0e0e0;
  color: #333;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.group-toggle-button.active {
  background-color: #007bff;
  color: #fff;
  box-shadow: 0 3px 6px rgba(0, 123, 255, 0.3);
}

.group-toggle-button:hover {
  background-color: #0056b3;
}

.bathroom-section,
.work-order-section {
  margin-bottom: 30px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f8f9fa;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.bathroom-section-title,
.work-order-section-title {
  font-size: 20px;
  color: #495057;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
}

.tickets-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 15px;
}

.ticket-item {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  background: linear-gradient(145deg, #f1f3f5, #ffffff);
  box-shadow: 4px 4px 8px #d1d9e0, -4px -4px 8px #ffffff;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.ticket-item:hover {
  transform: translateY(-5px);
  box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.1), -6px -6px 12px rgba(255, 255, 255, 0.5);
}

.ticket-item-title {
  font-size: 18px;
  color: #34495e;
  margin-bottom: 10px;
}

.ticket-item-detail {
  font-size: 14px;
  color: #555;
  margin: 5px 0;
}

.ticket-item-button {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #28a745;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.ticket-item-button:hover {
  background-color: #218838;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.ticket-item-button:active {
  background-color: #1e7e34;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

@media (max-width: 768px) {
  .tickets-container {
    padding: 20px;
  }

  .bathroom-section,
  .work-order-section {
    padding: 15px;
  }

  .ticket-item {
    padding: 10px;
  }

  .ticket-item-button {
    font-size: 12px;
    padding: 8px 16px;
  }
}
