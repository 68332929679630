.employees-container {
    padding: 20px;
    max-width: 900px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .toggle-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .toggle-buttons button {
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .toggle-buttons button:hover {
    background-color: #0056b3;
  }
  
  .employee-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-around;
  }
  
  .card-view .employee-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    width: 100%;
    max-width: 280px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
  }
  
  .card-view .employee-card:hover {
    transform: scale(1.05);
  }
  
  .employee-card h3 {
    margin-bottom: 10px;
    color: #333;
  }
  
  .employee-card p {
    margin: 5px 0;
    color: #555;
  }
  
  .list-view {
    display: block;
  }
  
  .employee-list-item {
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    border-radius: 10px;
    padding: 15px;
    transition: background-color 0.2s;
  }
  
  .employee-list-item h3 {
    margin-bottom: 5px;
  }
  
  .employee-list-item p {
    margin: 2px 0;
  }
  
  .employee-list-item:last-child {
    border-bottom: none;
  }

  .employee-list-item:hover {
    background-color: #ebeaea;
  }

    .employee-list-item.active {
        background-color: #cecdcd;
    }
  
/* Modal Form Adjustments */
.modal-content {
  padding: 20px;
  border-radius: 8px;
}

.modal-content h3 {
  margin-bottom: 20px;
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  color: #333;
}

.modal-form label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
}

.modal-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}

.cancel-button {
  background-color: #e0e0e0;
  color: #555;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.cancel-button:hover {
  background-color: #d6d6d6;
}

.add-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.add-button:hover {
  background-color: #0056b3;
}

.modal-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

    
    