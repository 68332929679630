.navigation-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  height: 100vh;
  background-color: #333;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-family: Arial, sans-serif;
  z-index: 1000;
  transition: width 0.3s ease;
}

.navigation-container.collapsed {
  width: 0px;
  padding: 10px;
}

.nav-header {
  text-align: center;
  margin-bottom: 30px;
  transition: opacity 0.3s ease;
}

.nav-header h2 {
  font-size: 1.5rem;
  color: #fff;
  margin: 0;
}

.nav-header,
.nav-links,
.logout-button {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.navigation-container.collapsed .nav-header,
.navigation-container.collapsed .nav-links,
.navigation-container.collapsed .logout-button {
  opacity: 0;
}

.nav-links {
  list-style: none;
  padding: 0;
  flex-grow: 1;
}

.nav-links li {
  margin-bottom: 15px;
}

.nav-links a {
  text-decoration: none;
  font-size: 1rem;
  color: #fff;
  padding: 10px 15px;
  border-radius: 5px;
  display: block;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.nav-links a:hover {
  background-color: #555;
  color: #fff;
}

.logout-button {
  margin-top: auto;
  background-color: #f16969;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  margin-bottom: 20%;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, opacity 0.3s ease;
}

.logout-button:hover {
  background-color: #f54242;
  color: #fff;
}

.collapse-button {
  position: absolute;
  top: 20px;
  right: -15px;
  background: #444;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1001;
}

.collapse-button:hover {
  background-color: #555;
}

@media (max-width: 768px) {
  .navigation-container {
    width: 180px;
    padding: 15px;
  }

  .navigation-container.collapsed {
    width: 60px;
    padding: 10px;
  }

  .nav-header h2 {
    font-size: 1.2rem;
  }

  .nav-links a {
    font-size: 0.9rem;
  }

  .logout-button {
    font-size: 0.9rem;
    padding: 8px 15px;
  }
}
