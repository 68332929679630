.dashboard {
  padding: 20px;
  max-width: 1400px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  background-color: #f7f9fc;
}

.dashboard-header {
  text-align: center;
  margin-bottom: 30px;
}

.dashboard-title {
  font-size: 32px;
  color: #333;
  margin-bottom: 10px;
}

.dashboard-description {
  font-size: 18px;
  color: #555;
}

.charts-grid {
  display: grid;
  grid-template-columns: 1fr 1fr; 
  gap: 40px;
  justify-content: center;
  align-items: center;
}

.chart-card {
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  padding: 30px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center; 
  width: 100%;
  max-width: 600px; 
}

.chart-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.chart-card-title {
  font-size: 20px;
  color: #444;
  margin-bottom: 20px;
  text-align: center;
}

.chart-wrapper {
  width: 100%; 
  max-width: 400px; 
  height: 300px; 
  display: flex;
  justify-content: center; 
  align-items: center; 
}

@media (max-width: 768px) {
  .charts-grid {
    grid-template-columns: 1fr; 
  }

  .chart-card {
    padding: 20px;
    max-width: 90%;
  }
}
