.create-account-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f3f4f6;
  }
  
  .create-account-content {
    background-color: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    width: 350px;
    text-align: center;
    width: 80%;
    max-width: 500px; 
  }
  
  h2 {
    margin-bottom: 30px;
    color: #333;
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 1px;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  label {
    text-align: left;
    font-size: 14px;
    margin-bottom: 5px;
    color: #555;
  }
  
  input {
    padding: 12px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
  }
  
  button {
    padding: 12px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #555;
  }
  
  .error {
    color: red;
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .success {
    color: green;
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  @media (max-width: 768px) {
    .create-account-content {
      width: 90%;
      padding: 20px;
    }
  
    h2 {
      font-size: 20px;
    }
  }
  
  .inline-fields {
    display: flex;
    gap: 20px;
  }
  
  .input-group {
    flex: 1; 
    display: flex;
    flex-direction: column;
  }
  
  .input-group label {
    text-align: left;
    font-size: 14px;
    margin-bottom: 5px;
    color: #555;
  }
  
  .input-group input {
    padding: 12px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
  }
  