.employee-page-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #f5f7fa; 
    font-family: "Inter", sans-serif; 
  }
  
  .employee-header {
    background-color: #333; 
    color: #fff;
    text-align: center;
    padding: 30px 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 0 0 15px 15px;
  }
  
  .employee-header-title {
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
    letter-spacing: 1px;
  }
  
  .employee-header-description {
    font-size: 1rem;
    margin: 10px 0 0 0;
    color: #dfe6e9;
  }
  
  .employee-main-content {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    background-color: #ffffff; 
    box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    margin: 20px 20px 10px 20px;
  }
  
  .employee-bottom-navigation {
    display: flex;
    justify-content: space-around;
    padding: 15px 0;
    background-color: #ffffff;
    border-top: 2px solid #dfe6e9;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
  }
  
  .employee-nav-tab {
    flex: 1;
    text-align: center;
    padding: 15px 0;
    font-size: 1rem;
    font-weight: bold;
    color: #fff; 
    background-color: #333; 
    border: none;
    cursor: pointer;
    border-radius: 0 0 15px 15px;
    margin: 0 10px;
    transition: all 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.employee-nav-tab.active {
    color: #ffffff;
    background-color: #555;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); 
}

.employee-nav-tab:hover {
    background-color: #444; 
    color: #ffffff;
}

  
  .employee-logout-button {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 10px 15px;
    font-size: 1rem;
    color: #fff;
    background-color: #d9534f;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .employee-logout-button:hover {
    background-color: #c9302c;
  }
  
  .employee-logout-button:active {
    background-color: #ac2925;
  }
  

  


  @media (max-width: 768px) {
    .employee-header-title {
      font-size: 1.8rem;
    }
  
    .employee-main-content {
      padding: 15px;
      margin: 15px;
    }
  
    .employee-nav-tab {
      font-size: 0.9rem;
    }
  }
  
  