.signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f3f4f6; 
  }
  
  .signup-content {
    background-color: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    width: 350px;
    text-align: center;
  }
  
  .signup-title {
    margin-bottom: 30px;
    color: #333;
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 1px;
  }
  
  .signup-form {
    display: flex;
    flex-direction: column;
  }
  
  .signup-form-label {
    text-align: left;
    font-size: 14px;
    margin-bottom: 5px;
    color: #555;
  }
  
  .signup-form-input {
    padding: 12px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
  }
  
  .signup-button {
    padding: 12px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .signup-button:hover {
    background-color: #218838;
  }
  
  .success {
    color: #28a745;
    font-size: 14px;
    margin-top: 10px;
  }
  
  .error {
    color: #dc3545;
    font-size: 14px;
    margin-top: 10px;
  }
  
  @media (max-width: 768px) {
    .signup-content {
      width: 90%;
      padding: 20px;
    }
  
    .signup-title {
      font-size: 20px;
    }
  }
  