.facility-dashboard-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #f4f6f9;
  font-family: Arial, sans-serif;
}

h2, h3 {
  color: #333;
  margin-bottom: 20px;
}

.chart-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-bottom: 40px;
}

.chart-container {
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  text-align: center;
}

.comments-section,
.reviews-section {
  margin-top: 30px;
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.comments-list,
.reviews-list {
  list-style: none;
  padding: 0;
}

.comment-item,
.review-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 15px;
  border-bottom: 1px solid #ddd;
}

.comment-item:last-child,
.review-item:last-child {
  border-bottom: none;
}

.comment-item p,
.review-item p {
  margin: 0;
}

strong {
  color: #444;
}

.delete-button {
  align-self: flex-start;
  background-color: #ff4d4d;
  color: #ffffff;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: #ff1a1a;
}

p {
  margin: 0;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .chart-grid {
    grid-template-columns: 1fr;
  }
}
.qr-code-section {
  margin-top: 30px;
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  text-align: center;
}

.qr-code-section h3 {
  color: #333;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.qr-code {
  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.qr-code img {
  max-width: 200px;
  height: auto;
}

.print-poster-button {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.print-poster-button:hover {
  background-color: #0056b3;
}

.qr-code-section .download-links {
  margin-top: 20px;
  text-align: center;
}

.qr-code-section .download-links p {
  margin: 10px 0;
  color: #555;
  font-size: 1rem;
  font-weight: bold;
}

.qr-code-section .download-links .placeholder {
  display: inline-block;
  width: 100px;
  height: 100px;
  background-color: #e0e0e0;
  border-radius: 10px;
  margin: 0 10px;
  line-height: 100px;
  text-align: center;
  font-size: 0.875rem;
  color: #888;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .qr-code {
    flex-direction: column;
    padding: 10px;
  }

  .qr-code img {
    max-width: 150px;
  }

  .qr-code-section .download-links {
    margin-top: 15px;
  }

  .qr-code-section .download-links .placeholder {
    width: 80px;
    height: 80px;
    line-height: 80px;
    margin: 5px;
  }
}
